'use client';

import React from 'react';
import { Progress } from '@chakra-ui/react';

export const LOADER_HEIGHT = 4;

const LoaderProgressBar = () => {
    return <Progress size="xs" colorScheme="purple" isIndeterminate={true} />;
};

export default LoaderProgressBar;
