'use client';

import React from 'react';
import Link from 'libs/global/components/NextConversionLink';
import { helpCenterPath, homePath } from 'libs/routes';
import { WheelhouseLogoIcon } from 'libs/global/components/Pro/styles/proIcons';
import { useIsMobile } from 'libs/global/containers/IsMobile';
import { Link as ExtLink } from '@chakra-ui/react';

export const HEADER_HEIGHT = 50;

interface HeaderProps {
    mainItems: React.ReactNode[];
}

const Logo = () => {
    return (
        <div
            style={{
                display: 'flex',
            }}>
            <Link
                to={homePath()}
                locale="false"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 10px 0 15px',
                    textDecoration: 'none',
                }}>
                {WheelhouseLogoIcon()}
                <div
                    style={{
                        marginLeft: 7,
                        fontSize: 20,
                        fontWeight: 400,
                    }}>
                    Wheelhouse
                    <sup>&trade;</sup>
                </div>
            </Link>
        </div>
    );
};

const MainItems = ({ mainItems }: HeaderProps) => {
    const isMobile = useIsMobile();
    if (isMobile) {
        return null;
    }

    return <>{mainItems}</>;
};

const BaseHeader = ({ mainItems }: HeaderProps) => {
    return (
        // Container Div so height is preserved if inner div has position fixed.
        <div
            style={{
                height: HEADER_HEIGHT,
            }}>
            <div
                className="justify-between-s"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                    fontWeight: 500,
                    height: HEADER_HEIGHT,
                    backgroundColor: '#fff',
                    position: 'relative',
                    boxShadow: '0 1px 3px 0 rgba(38,23,54,.15)',
                }}>
                <Logo />
                <div className="dn-s" style={{ flexGrow: 1 }} />
                <MainItems mainItems={mainItems} />
            </div>
        </div>
    );
};

const Header = ({ mainItems }: HeaderProps) => {
    mainItems = [...(mainItems || [])];

    mainItems.push(
        <ExtLink key="help" href={helpCenterPath()} className="mh3-ns mh2 pv2">
            Help
        </ExtLink>,
    );

    return <BaseHeader mainItems={mainItems} />;
};

export default Header;
