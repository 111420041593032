import analytics from 'libs/utils/analytics';
import { showSuccess, startLoading, stopLoading, stopLoadingError, stopLoadingSuccess } from 'libs/global/slices/ui';
import {
    changePasswordAPI,
    dashboardPath,
    forgotPasswordAPI,
    loginAPI,
    loginPath,
    reactivateMainAccountAPI,
    registerAPI,
    sendReactivateEmailAPI,
} from 'libs/routes';
import Router from 'next/router';
import { post, put } from 'libs/requests';
import { AppDispatch } from 'libs/global/store';
import { User } from 'libs/types/user';

// redirect with query param `redirect_to`
function redirectTo(redirect_to: string) {
    if (redirect_to) {
        window.location.href = redirect_to;
    } else {
        window.location.href = dashboardPath();
    }
}

interface LoginPayload {
    'user[email]': string;
    'user[password]': string;
    'user[remember_me]'?: boolean;
    'cf-turnstile-response'?: string;
}
export function submitLogin(payload: LoginPayload, onFail?: () => void) {
    return (d: AppDispatch) => {
        d(startLoading('Logging in to Wheelhouse'));
        return post(loginAPI(), payload)
            .then((response) => {
                if (response.data) {
                    redirectTo(Router.router!.query.redirect_to as string);
                } else {
                    // for preserving redirect query param if wrong email/password
                    Router.push({
                        pathname: loginPath(),
                        query: { code: 2, ...Router.router!.query },
                    });
                    d(stopLoading());
                }
            })
            .catch((err) => {
                Router.push({
                    pathname: loginPath(),
                    query: { code: 2, ...Router.router!.query },
                });
                d(stopLoading());
                onFail?.();
            });
    };
}

function _trackRegistration(user: User, callback: () => void) {
    analytics.alias(user.id, {
        ...user,
    });
    analytics.identify(user.id.toString(), {
        ...user,
        company: {
            name: user.company || '',
        },
    });
    (window as any).fbq && (window as any).fbq('track', 'CompleteRegistration');
    // This event should trigger a Google Tag Manager conversion.
    analytics.track('Register Page: Submitted', {}, {}, callback);
}
interface SimpleLoginPayload {
    'user[email]': string;
    'user[password]': string;
}

export function submitLoginSimple(payload: SimpleLoginPayload, onSuccess = (data) => {}, onError = (error) => {}) {
    post(loginAPI(), payload)
        .then((response) => {
            onSuccess(response.data);
        })
        .catch(onError);
}

interface SimpleRegistrationPayload {
    'user[email]': string;
    'user[password]': string;
    'user[accepted_toa]'?: boolean;
    'user[allow_marketing_emails]'?: boolean;
    'user[company]'?: string;
    'user[default_pricing_tier_level]'?: number;
    'user[first_name]'?: string;
    'user[last_name]'?: string;
    'user[name]'?: string;
    'user[portfolio_size]'?: string;
    referrer_id?: string;
    'cf-turnstile-response'?: string;
}
export function submitRegistrationSimple(payload: SimpleRegistrationPayload, onSuccess = (user) => {}, onTrack = (user) => {}, onError = (error) => {}) {
    return post(registerAPI(), payload)
        .then((response) => {
            onSuccess(response.data.user);
            // _trackRegistration may not call the callback if adblocker is turned on.
            // If it doesn't occur in 3000ms call onTrack so registration can be completed.
            const timeout = window.setTimeout(() => {
                onTrack(response.data.user);
            }, 3000);
            _trackRegistration(response.data.user, () => {
                window.clearTimeout(timeout);
                onTrack(response.data.user);
            });
        })
        .catch(onError);
}

export function submitRegistration(payload: SimpleRegistrationPayload, onFail?: () => void) {
    return (d: AppDispatch) => {
        d(startLoading('Creating your account'));
        return submitRegistrationSimple(
            payload,
            () => {
                d(showSuccess('Your account has been successfully created!'));
            },
            () => {
                // Unclear if GTM events still happen asynchronously with this callback, so set a timeout.
                // (See GTM's dataLayer docs for more details.)
                setTimeout(() => {
                    window.location.href = (Router.router!.query.redirect_to as string) || dashboardPath();
                }, 500);
            },
            (err) => {
                const data = err?.response?.data || {};
                const message = data.message || 'Error occurred while creating your account. Please try again.';
                d(stopLoadingError(message));
                onFail?.();
            },
        );
    };
}

export function sendForgotPasswordEmail(payload: { 'user[email]': string }) {
    return (d: AppDispatch) => {
        d(startLoading('Sending Forgot Password Email'));
        return post(forgotPasswordAPI(), payload)
            .then((response) => {
                Router.push({
                    pathname: loginPath(),
                    query: { code: 3, ...Router.router!.query },
                });
                d(stopLoading());
            })
            .catch((err) => {
                let message = 'Something went wrong.';
                if (err.response.data && err.response.data.error && err.response.data.error.message) {
                    message = err.response.data.error.message;
                }
                d(stopLoadingError(message));
            });
    };
}

interface ChangePasswordPayload {
    user: {
        reset_password_token: string;
        password: string;
        password_confirmation: string;
    };
}
export function changePassword(payload: ChangePasswordPayload) {
    return (d: AppDispatch) => {
        d(startLoading('Changing Password'));
        return put(changePasswordAPI(), payload)
            .then((response) => {
                d(stopLoadingSuccess('Password Changed'));
                redirectTo(dashboardPath());
            })
            .catch((err) => {
                const messages: string[] = [];
                if (err?.response?.data?.errors) {
                    for (const key in err.response.data.errors) {
                        messages.push(`${key}: ${err.response.data.errors[key]}`);
                    }
                }

                let message = 'Something went wrong.';
                if (messages.length) {
                    message = messages.join(' ');
                }
                d(stopLoadingError(message));
            });
    };
}

export function sendReactivateEmail(payload: { email: string }) {
    return (d: AppDispatch) => {
        d(startLoading('Emailing your account'));
        return put(sendReactivateEmailAPI(), payload)
            .then((response) => {
                Router.push(loginPath());
                d(stopLoadingSuccess(`Alright. Instructions to reactivate your account have been emailed to ${payload.email}. Please check your email.`));
            })
            .catch((err) => {
                d(stopLoadingError('Error occurred while sending instructions to reactivate your account, please try again.'));
            });
    };
}

export function reactivateMainAccount(payload: { password: string; hash: string }) {
    return (d: AppDispatch) => {
        d(startLoading('Reactivating your account'));
        return put(reactivateMainAccountAPI(), payload)
            .then((response) => {
                Router.push(loginPath());
                d(stopLoadingSuccess('Your account has been successfully reactivated!'));
            })
            .catch((err) => {
                d(stopLoadingError('Error occurred while reactivating your account, please try again.'));
            });
    };
}
