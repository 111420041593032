import React, { useContext, useEffect } from 'react';
import { dashboardPath } from 'libs/routes';
import { useRouter } from 'next/router';
import { Context } from 'libs/global/context/requiredDataFetching';
import LoaderProgressBar from 'libs/harmony/LoaderProgressBar';
import { useAppSelector } from '../hooks';

export default function RequireLoggedOutWrapper(Component: React.ComponentType) {
    const RequireLoggedOutWrapper = (props: any) => {
        const router = useRouter();
        const userLoggedIn = useAppSelector(({ user }) => !!(user && user.id));
        const { userFetched } = useContext(Context);
        useEffect(() => {
            if (userLoggedIn && userFetched) {
                router.replace(dashboardPath());
            }
        }, [router, userLoggedIn, userFetched]);

        if (!userFetched) {
            return <LoaderProgressBar />;
        }

        return !userLoggedIn ? <Component {...props} /> : null;
    };

    return RequireLoggedOutWrapper;
}
