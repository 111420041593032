import React from 'react';
import { theme } from 'libs/wheelhouse_design/theme';
const { colors } = theme;

const WheelhouseLogoIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.4012 3H1.80467C1.43403 3 1.19223 3.38914 1.35638 3.72145L8.49458 18.1717C8.68202 18.5511 9.22667 18.5406 9.39935 18.1543L15.8577 3.70402C16.0055 3.37327 15.7635 3 15.4012 3Z"
            fill="#D513D2"
        />
        <path
            d="M22.2625 3H7.86833C7.49896 3 7.2571 3.38672 7.41876 3.71884L15.3356 19.9824C15.5256 20.3729 16.089 20.3521 16.2496 19.9486L22.727 3.685C22.8578 3.35667 22.6159 3 22.2625 3Z"
            fill="#A30FA6"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.06885 3L11.8151 12.7503L16.1729 3H7.06885Z" fill="#8E0A91" />
    </svg>
);

const ProUserDashboardIcon = (color?: string, size = 18) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-user">
        <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
        <circle cx="12" cy="7" r="4" />
    </svg>
);

const ProCalendarBookingIcon = (color?: string, size = 16) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color || colors.gray[400]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
    </svg>
);

const ProCalendarDayBlockedIcon = (color?: string, size = 16) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color || colors.gray[400]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
        <path d="M7 11V7a5 5 0 0 1 10 0v4" />
    </svg>
);

const ProInfoIcon = (color?: string, size = 12) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color || '#a29da2'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <circle cx="12" cy="12" r="10" />

        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
        <line x1="12" y1="17" x2="12" y2="17" />
    </svg>
);

const ProMenuIcon = (size = 16) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-menu">
        <path d="M3 12h18M3 6h18M3 18h18" />
    </svg>
);

export { WheelhouseLogoIcon, ProInfoIcon, ProCalendarBookingIcon, ProCalendarDayBlockedIcon, ProUserDashboardIcon, ProMenuIcon };
