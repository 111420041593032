'use client';

import React from 'react';
import Link from 'libs/global/components/NextConversionLink';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { purple } from 'libs/utils/colors';

interface AnchorProps {
    kind?: 'primary';
    style?: Partial<LinkProps>;
    to?: string;
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const getStyles = (props: AnchorProps) => {
    let styles: LinkProps = {
        display: 'inline-block',
        fontWeight: 500,
        textDecoration: 'underline',
    };
    if (props.kind == 'primary') {
        styles.textDecoration = 'none';
        styles.color = purple;
        styles._hover = {
            textDecoration: 'underline',
        };
    }
    if (props.style) {
        styles = {
            ...styles,
            ...props.style,
        };
    }

    return styles;
};

/**
 * @deprecated Use NextConversionLink or Chakra directly.
 */
const Anchor = (props: AnchorProps & LinkProps) => {
    const anchorProps = Object.assign({}, props);
    delete anchorProps.kind;

    const Component = props.to ? Link : ChakraLink;

    // React will stop allowing javascript:void(0) as a valid href,
    // so if href is not set, set it to # (for cursor styling)
    // and prevent the default action.
    return (
        <Component
            {...anchorProps}
            href={props.href || '#'}
            {...getStyles(props)}
            onClick={(e) => {
                if (!props.href && !props.to) {
                    e.preventDefault();
                }
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
        />
    );
};

export default Anchor;
