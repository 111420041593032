'use client';

import React from 'react';
import { loginPath, registerPath } from 'libs/routes';
import Anchor from 'libs/global/components/Anchor';
import BaseHeader from 'libs/global/components/BaseHeader';
import { useRouter } from 'next/router';
import { useIntercom } from 'libs/global/hooks/useIntercom';

const iconTalk = '/icons/icon-talk.svg';

const SignupPageContainer = ({ children }: { children: React.ReactNode }) => {
    const showIntercom = useIntercom();
    const router = useRouter();
    const redirect_to = router.query.redirect_to as string | undefined;

    const renderAccountMenuItem = () => {
        if (router.pathname != loginPath()) {
            return (
                <SignupHeaderItem key="have-account">
                    <span>
                        Have an account?{' '}
                        <Anchor to={loginPath(redirect_to)} kind="primary">
                            Log in
                        </Anchor>
                    </span>
                </SignupHeaderItem>
            );
        } else {
            return (
                <SignupHeaderItem key="dont-have-account">
                    <span>
                        Don’t have an account?{' '}
                        <Anchor to={registerPath(redirect_to)} kind="primary">
                            Sign up
                        </Anchor>
                    </span>
                </SignupHeaderItem>
            );
        }
    };

    const renderMenuItems = () => {
        return [
            <SignupHeaderItem key="talk-with-a-guide" icon={iconTalk} onClick={showIntercom}>
                Talk with a guide
            </SignupHeaderItem>,
            renderAccountMenuItem(),
        ];
    };

    return (
        <div className="UnauthorizedContainer">
            <BaseHeader mainItems={renderMenuItems()} />
            {children}
        </div>
    );
};

interface SignupHeaderItemProps {
    onClick?: () => void;
    icon?: string;
    children: React.ReactNode;
}

const SignupHeaderItem = ({ onClick, icon, children }: SignupHeaderItemProps) => {
    return (
        <div
            onClick={onClick}
            style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginRight: 15,
                whiteSpace: 'nowrap',
            }}>
            {icon && <img alt="" role="presentation" src={icon} style={{ marginRight: 10, height: 17, width: 17 }} />}
            {children}
        </div>
    );
};

export default SignupPageContainer;
